import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_MAILMESSAGE,
    DELETE_MAILMESSAGE,
    MAILMESSAGE_FORM_TOOGLE_LOADING,
    SET_MAILMESSAGE_LIST,
    SHOW_NOTIFICATION,
    UPDATE_MAILMESSAGE,
} from "../constants";
import { formatMailMessages } from "./settingsActionsUtils";

/* MAILMESSAGE LIST */
export const fetchMailMessages = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.mailMessages.list)
            .then((response) => {
                const mailMessages = formatMailMessages(response.data);
                dispatch({
                    type: SET_MAILMESSAGE_LIST,
                    payload: keyBy(mailMessages, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// MAILMESSAGES
export const createMailMessage = (newMailMessage) => {
    return async (dispatch) => {
        dispatch({ type: MAILMESSAGE_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.mailMessages.create, newMailMessage)
            .then((response) => {
                const mailMessage = formatMailMessages(response.data);
                dispatch({ type: ADD_NEW_MAILMESSAGE, payload: mailMessage });
                dispatch({ type: MAILMESSAGE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Correo creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MAILMESSAGE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateMailMessage = (updatedMailMessage) => {
    return async (dispatch) => {
        dispatch({ type: MAILMESSAGE_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.mailMessages.edit}${updatedMailMessage && updatedMailMessage._id}`, updatedMailMessage)
            .then((response) => {
                const mailMessage = formatMailMessages(response.data);
                dispatch({ type: UPDATE_MAILMESSAGE, payload: mailMessage });
                dispatch({ type: MAILMESSAGE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Correo actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MAILMESSAGE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteMailMessages = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: MAILMESSAGE_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.mailMessages.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_MAILMESSAGE, payload: Ids });
                dispatch({ type: MAILMESSAGE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Correo eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MAILMESSAGE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
