import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_BOOKTYPE,
    DELETE_BOOKTYPE,
    BOOKTYPE_FORM_TOOGLE_LOADING,
    SET_BOOKTYPE_LIST,
    SHOW_NOTIFICATION,
    UPDATE_BOOKTYPE,
} from "../constants";
import { formatBooktypes } from "./settingsActionsUtils";

/* BOOKTYPE LIST */
export const fetchBooktypes = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.booktypes.list)
            .then((response) => {
                const booktypes = formatBooktypes(response.data);
                dispatch({
                    type: SET_BOOKTYPE_LIST,
                    payload: keyBy(booktypes, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// BOOKTYPES
export const createBooktype = (newBooktype) => {
    return async (dispatch) => {
        dispatch({ type: BOOKTYPE_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.booktypes.create, newBooktype)
            .then((response) => {
                const booktype = formatBooktypes(response.data);
                dispatch({ type: ADD_NEW_BOOKTYPE, payload: booktype });
                dispatch({ type: BOOKTYPE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Tipo de reserva creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: BOOKTYPE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateBooktype = (updatedBooktype) => {
    return async (dispatch) => {
        dispatch({ type: BOOKTYPE_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.booktypes.edit}${updatedBooktype && updatedBooktype._id}`, updatedBooktype)
            .then((response) => {
                const booktype = formatBooktypes(response.data);
                dispatch({ type: UPDATE_BOOKTYPE, payload: booktype });
                dispatch({ type: BOOKTYPE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Tipo de reserva actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: BOOKTYPE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteBooktypes = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: BOOKTYPE_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.booktypes.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_BOOKTYPE, payload: Ids });
                dispatch({ type: BOOKTYPE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Tipo de reserva eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: BOOKTYPE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
