import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  SET_CLASSROOM_LIST,
} from "../constants";
import { formatClassrooms } from "./settingsActionsUtils";

/* CLASSROOM LIST */
export const fetchClassrooms = () => {
  return async (dispatch) => {
    return await axios
      .get(API.classrooms.list)
      .then((response) => {
        const classrooms = formatClassrooms(response.data);
        dispatch({
          type: SET_CLASSROOM_LIST,
          payload: keyBy(classrooms, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
  };
};
